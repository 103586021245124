import React, { FC } from 'react';

import { NewsGridTagProps } from './models';

import './NewsGridTag.scss';

const NewsGridTag: FC<NewsGridTagProps> = ({ tagName }) => (
  <span className="article__tag tag">{tagName}</span>
);

export default NewsGridTag;
