import React, { FC } from 'react';

import { hexToRgb } from 'utils/colorsHandler';
import { formatDisplayDate } from 'utils/dateHandler';

import { NewsGridInformationProps } from './models';

import './NewsGridInformation.scss';

const NewsGridInformation: FC<NewsGridInformationProps> = ({
  tagName,
  creationDate,
  articleTypeColor,
  readingTime,
  readingTimeText,
  featured,
  lang,
}) => (
  <div className="article__information-wrap">
    <div className="article__type-time">
      {featured ? <span className="article__featured tag">featured</span> : null}
      {tagName && articleTypeColor ? (
        <span
          className="article__type tag"
          style={{
            backgroundColor: `rgba(${hexToRgb(articleTypeColor)}, 0.2)`,
            border: `1px solid rgba(${hexToRgb(articleTypeColor)}, 0.12)`,
          }}
        >
          {tagName}
        </span>
      ) : null}
      <span className="article__time tag">{formatDisplayDate(creationDate, lang)}</span>
    </div>
    {readingTime >= 1 ? (
      <div className="article__read-views">
        <span className="article__read tag">{`${readingTime} ${readingTimeText}`}</span>
      </div>
    ) : null}
  </div>
);

export default NewsGridInformation;
