import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';

import StatisticsWithImagesItems from '../StatisticsWithImagesItems';
import { StatisticsWithImagesBlockProps } from './models';

import './StatisticsWithImagesBlock.scss';

const StatisticsWithImagesBlock: FC<StatisticsWithImagesBlockProps> = ({ block }) => (
  <div
    className={classNames('statistics-with-images__wrapp', {
      'statistics-with-images__wrapp--left-image': block.isImageOnLeft,
    })}
  >
    <StatisticsWithImagesItems block={block} />
    {block.image ? (
      <Image
        className="statistics-with-images__image"
        imageData={block.image}
        alt={block.image.altText}
      />
    ) : null}
  </div>
);

export default StatisticsWithImagesBlock;
