import React, { FC } from 'react';
import classNames from 'classnames';

import { Col } from 'layout';

import { NewsGridArticlesProps } from './models';
import NewsGridArticle from './NewsGridArticle';

import './NewsGridArticles.scss';

const NewsGridArticles: FC<NewsGridArticlesProps> = ({
  articlesNews,
  numberOfColString,
  articles,
  lang,
  readingTimeText,
  writtenByText,
  hasTitle,
}) => (
  <Col
    xl={numberOfColString}
    md="6"
    sm="12"
    className={classNames('newsGrid col--no-gutters', {
      'newsGrid--twoCol': articlesNews?.length === 2,
      'newsGrid--multipleCol': articlesNews?.length > 2,
    })}
  >
    {articles?.map((article) => (
      <NewsGridArticle
        article={article}
        lang={lang}
        readingTimeText={readingTimeText}
        writtenByText={writtenByText}
        key={article.title}
        hasTitle={hasTitle}
      />
    ))}
  </Col>
);

export default NewsGridArticles;
