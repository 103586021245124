import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import Envelope from '../../../common/icons/envelope.svg';
import { ContactUsBoxProps } from './models';

import './ContactUsBox.scss';

const ContactUsBox: FC<ContactUsBoxProps> = ({
  title,
  phoneNumber,
  emailUsButtonText,
  email,
  ctaLink,
}) => (
  <div className="contact-item">
    <h3 className="contact-item__tag tag tag--bold">{title}</h3>
    {phoneNumber ? (
      <a className="contact-item__info h6" href={`tel:${phoneNumber.replace(/\s+/g, '')}`}>
        {phoneNumber}
      </a>
    ) : null}
    {email ? (
      <a
        className="link contact-item__email"
        href={`mailto:${email.replace(/\s+/g, '')}`}
        aria-label={`${emailUsButtonText}: ${title}`}
      >
        <span>{emailUsButtonText}</span>
        <Envelope aria-hidden="true" facusable="false" />
      </a>
    ) : null}
    <LinkItem
      link={ctaLink}
      linkClass="contact-item__cta link link--download"
      titleClass="some title class"
      hasIcon
      isLink
      showTitle
    />
  </div>
);

export default ContactUsBox;
