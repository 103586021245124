import React, { FC } from 'react';
import { Link } from 'gatsby';

import NewsGridAuthors from 'common/NewsGrid/NewsGridAuthors';
import NewsGridImage from 'common/NewsGrid/NewsGridImage';
import NewsGridInformation from 'common/NewsGrid/NewsGridInformation';
import NewsGridTags from 'common/NewsGrid/NewsGridTags';
import { formatDisplayDate } from 'utils/dateHandler';

import { NewsGridArticleProps } from './models';

import './NewsGridArticle.scss';

const NewsGridArticle: FC<NewsGridArticleProps> = ({
  article,
  lang,
  readingTimeText,
  writtenByText,
  hasTitle,
}) => (
  <div className="article">
    <Link to={article.url} className="article__title-link">
      <span className="sr-only">
        {article.title}
        {article.authors && article.authors.length ? `Written by: ${article.authors}` : null}
        {`Published on: ${formatDisplayDate(article.creationDate, lang)}`}
        {`Time to read: ${article.readingTime} minute`}
        {article.articleTags
          ? `Tags: ${article.articleTags.map((tag) => tag.name)}`
          : `No related tags`}
      </span>
    </Link>
    <div className="article__title-container">
      {React.createElement(
        hasTitle ? 'h3' : 'h2',
        { className: 'article__title h5' },
        article.title
      )}
      {/* <h3 className="article__title h5" data-testid="article-title">
        {article.title}
      </h3> */}
      <p className="article__description caption">{article.previewText}</p>
    </div>
    {article.authors && article.authors.length ? (
      <NewsGridAuthors authors={article.authors} writtenByText={writtenByText} />
    ) : null}
    <NewsGridInformation
      tagName={article.articleType?.tagName}
      creationDate={article.creationDate}
      articleTypeColor={article.articleType?.color}
      readingTime={article.readingTime}
      lang={lang}
      readingTimeText={readingTimeText}
    />
    <NewsGridTags article={article.articleTags} />
    <NewsGridImage article={article.previewImage} />
  </div>
);

export default NewsGridArticle;
