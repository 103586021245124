import React, { FC } from 'react';

import NewsGridArticles from '../NewsGridArticles';
import { ArticlesNewsProps } from './models';

const ArticlesNews: FC<ArticlesNewsProps> = ({
  articlesNews,
  numberOfColString,
  lang,
  readingTimeText,
  writtenByText,
  hasTitle,
}) => (
  <>
    {articlesNews?.map((articles, i) => (
      <NewsGridArticles
        articlesNews={articlesNews}
        numberOfColString={numberOfColString}
        articles={articles}
        lang={lang}
        readingTimeText={readingTimeText}
        writtenByText={writtenByText}
        key={articles[1] ? articles[1].id : i}
        hasTitle={hasTitle}
      />
    ))}
  </>
);

export default ArticlesNews;
