import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { SimpleCtaBlockProps } from './models';

import './SimpleCtaBlock.scss';

const SimpleCtaBlock: FC<SimpleCtaBlockProps> = ({ text, ctaLink }) => (
  <div className="simple-cta-blocks__item-holder">
    <h4 className="simple-cta-blocks__item-title h6">{text}</h4>
    <LinkItem
      link={ctaLink}
      linkClass="simple-cta-blocks__item-link link link--pink"
      titleClass="simple-cta-blocks__item-link-title"
      ariaLabel={`${ctaLink[0]?.name} ${text}`}
      hasIcon
      isLink
      showTitle
    />
  </div>
);

export default SimpleCtaBlock;
