import React, { FC } from 'react';

import ImageAndFact from '../ImageAndFact';
import TitleAndText from '../TitleAndText';
import { RightSideRendererProps } from './models';

const Components = {
  HealthierPlanetScrollerImageAndFact: ImageAndFact,
  HealthierPlanetScrollerTitleAndText: TitleAndText,
};

const RightSideRenderer: FC<RightSideRendererProps> = ({ items }) => (
  <>
    {items?.map((item, index) => {
      if (typeof Components[item.__typename] === 'undefined') {
        return null;
      }

      const Component = Components[item.__typename];

      return <Component key={`${item.__typename}${index}`} {...item} />;
    })}
  </>
);

export default RightSideRenderer;
