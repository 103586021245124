import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useOnScreen from 'hooks/useOnScreen';

import Image from 'common/Image';
import { isBrowser } from 'utils/browser';
import { TABLET_DEVICE } from 'utils/constants';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { ImageAndFactProps } from './models';

import './ImageAndFact.scss';

const ImageAndFact: FC<ImageAndFactProps> = ({ image, fact, factSupportingText }) => {
  const [screen, setScreen] = useState<boolean | number>();
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);
  const visibleRef = useRef(null);

  let percentageOfElementIsVisible;

  if (screen) {
    percentageOfElementIsVisible = screen < TABLET_DEVICE ? 0.5 : 0.15;
  }

  const isVisible = useOnScreen(visibleRef, percentageOfElementIsVisible);

  const classesForDiv = classNames('healthierPlanet__image-fact healthierPlanet__right-content', {
    'healthierPlanet__image-fact--only-text': image === null,
    'healthierPlanet__image-fact--only-photo': fact === null && factSupportingText === null,
    'healthierPlanet__image-fact--visible': isVisible && !isMobile && !isSmallMobile,
  });

  const classesForTitle = classNames('rte healthierPlanet__image-title', {
    statistic: isMobile || isSmallMobile,
    h1: !isMobile && !isSmallMobile,
  });

  const classesForDes = classNames('rte healthierPlanet__image-desc', {
    'tag tag--bold': isMobile || isSmallMobile,
    h6: !isMobile && !isSmallMobile,
  });

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }
  }, []);

  return (
    <div ref={visibleRef} className={classesForDiv}>
      {image ? (
        <Image imageData={image} alt={image.altText} className="healthierPlanet__healthier-image" />
      ) : null}
      <div className="healthierPlanet__image-text">
        {fact ? (
          <span
            role="heading"
            aria-level={3}
            className={classesForTitle}
            dangerouslySetInnerHTML={{ __html: fact }}
          />
        ) : null}
        {factSupportingText ? (
          <div className={classesForDes} dangerouslySetInnerHTML={{ __html: factSupportingText }} />
        ) : null}
      </div>
    </div>
  );
};

export default ImageAndFact;
