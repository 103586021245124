import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedArticlePreviewNewsAndStoriesImages } from './utils';

const defaultBreakpoints: ImagesContent.ArticlePreviewNewsAndStoriesImageBreakpoint[] = [
  { alias: 'newsAndStoriesMobile', media: '(max-width: 767px)' },
  { alias: 'newsAndStoriesTablet', media: '(max-width: 1199px)' },
];

const ArticlePreviewNewsAndStoriesImage: FC<ImagesContent.ArticlePreviewNewsAndStoriesImageProps> =
  ({ imageData, alt, breakpoints = defaultBreakpoints, ...restProps }) => {
    const isArtDirected = Object.keys(imageData).includes('newsAndStoriesDesktop');
    const mainImage = isArtDirected
      ? getImage(
          (imageData as ImagesContent.ArticlePreviewNewsAndStoriesImage).newsAndStoriesDesktop
        )
      : getImage(imageData as ImagesContent.ImageDataLike);

    const images = withArtDirection(
      mainImage as IGatsbyImageData,
      getArtDirectedArticlePreviewNewsAndStoriesImages(
        breakpoints,
        imageData as ImagesContent.ArticlePreviewNewsAndStoriesImage
      )
    );

    return <GatsbyImage image={images} alt={alt} {...restProps} />;
  };

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.ArticlePreviewNewsAndStoriesImageBreakpoint[]
  ): FC<ImagesContent.ArticlePreviewNewsAndStoriesImageProps> =>
  (props: ImagesContent.ArticlePreviewNewsAndStoriesImageProps) =>
    <ArticlePreviewNewsAndStoriesImage {...props} breakpoints={breakpoints} />;

export default ArticlePreviewNewsAndStoriesImage;
