import React, { FC } from 'react';

import Image from 'common/Image';

import { NewsGridAuthorsImageProps } from './models';

import './NewsGridAuthorsImage.scss';

const NewsGridAuthorsImage: FC<NewsGridAuthorsImageProps> = ({ authorPhoto, authorAlt }) => (
  <div className="article__author-img-container">
    <Image
      className="article__author-img"
      imageData={authorPhoto}
      alt={authorAlt}
      aria-hidden="true"
    />
  </div>
);

export default NewsGridAuthorsImage;
