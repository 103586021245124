import React, { FC, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { arraySplitter } from 'utils/arraysHandler';
import { isBrowser } from 'utils/browser';
import { DESKTOP_DEVICE, TABLET_DEVICE } from 'utils/constants';

import { ModulesContent } from '../../@types/content/modules';
import ArticlesNews from './ArticlesNews';
import { NewsGridProps } from './models';

const NewsGrid: FC<NewsGridProps> = ({
  sortedArticles,
  numberOfCol,
  numberOfColTablet,
  lang,
  readingTimeText,
  writtenByText,
  hasTitle,
}) => {
  const [articlesNews, setArticlesNews] = useState<ModulesContent.ArticlePagePreviewType[][]>(
    new Array(numberOfCol).fill([])
  );
  const [screen, setScreen] = useState<number | boolean>();

  const numberOfColString = String(12 / numberOfCol);

  const sortElementsInCol = (splitedArray) => {
    const newArray = new Array(numberOfCol).fill([]);

    for (let i = 0; i < newArray.length; i++) {
      newArray[i] = new Array(1);
    }

    for (let i = 0; i < splitedArray.length; i++) {
      for (let j = 0; j < splitedArray[i].length; j++) {
        newArray[j]?.push(splitedArray[i][j]);
      }
    }

    setArticlesNews(newArray);
  };

  const handleResize = debounce(() => {
    setScreen(window.innerWidth);
  }, 100);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!screen) return;

    if (screen >= DESKTOP_DEVICE) {
      const spliterArrayDesktop = arraySplitter(sortedArticles, numberOfCol);
      sortElementsInCol(spliterArrayDesktop);
    } else if (screen >= TABLET_DEVICE && screen < DESKTOP_DEVICE) {
      const spliterArrayTablet = arraySplitter(sortedArticles, numberOfColTablet || 2);
      sortElementsInCol(spliterArrayTablet);
    } else {
      const spliterArrayMobile = arraySplitter(sortedArticles, 1);
      sortElementsInCol(spliterArrayMobile);
    }
  }, [sortedArticles, screen]);

  return (
    <ArticlesNews
      articlesNews={articlesNews}
      numberOfColString={numberOfColString}
      lang={lang}
      readingTimeText={readingTimeText}
      writtenByText={writtenByText}
      hasTitle={hasTitle}
    />
  );
};

export default NewsGrid;
