import React, { FC } from 'react';

import { TitleProps } from './models';

import './Title.scss';

const Title: FC<TitleProps> = ({ title }) => {
  if (!title) return null;

  return <h2 className="simple-cta-blocks__title h4">{title}</h2>;
};

export default Title;
