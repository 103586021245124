import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { AccordionLinksProps } from './models';

const AccordionLinks: FC<AccordionLinksProps> = ({ links }) => {
  if (!links || links.length <= 0) return null;

  return (
    <div className="accordion__link-container">
      {links[0] ? (
        <LinkItem
          link={[{ ...links[0] }]}
          linkClass="accordion__link btn btn--pink"
          hasIcon
          isLink
          showTitle
        />
      ) : null}
      {links[1] ? (
        <LinkItem
          link={[{ ...links[1] }]}
          linkClass="accordion__link btn btn--outline"
          hasIcon
          isLink
          showTitle
        />
      ) : null}
    </div>
  );
};

export default AccordionLinks;
