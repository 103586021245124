import React, { FC } from 'react';
import IframeResizer from 'iframe-resizer-react';

import { Col, Container, Row } from 'layout';

import { IFrameProps } from './models';

import './IFrame.scss';

const IFrame: FC<IFrameProps> = ({ module }) => (
  <section className="iframe-container section">
    <Container>
      <Row>
        <Col sm="12" className="col--no-gutters">
          <IframeResizer
            className="iframe-container__iframe"
            heightCalculationMethod="lowestElement"
            src={module.sourceUrl}
            title={module.iFrameTitle}
            style={{ width: '1px', minWidth: '100%' }}
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default IFrame;
