import React, { FC } from 'react';

import { NewsGridAuthorsProps } from './models';
import NewsGridAuthorsImage from './NewsGridAuthorsImage';
import NewsGridAuthorsName from './NewsGridAuthorsName';

import './NewsGridAuthors.scss';

const NewsGridAuthors: FC<NewsGridAuthorsProps> = ({ authors, writtenByText }) => (
  <div className="article__authors-container">
    <div className="article__author-img-wrapp">
      {authors?.map((author) => (
        <NewsGridAuthorsImage
          authorAlt={author.profilePhoto.altText}
          authorPhoto={author.profilePhoto}
          key={author.name}
        />
      ))}
    </div>
    <div className="article__author-description">
      <p className="article__author-text tag">
        {writtenByText}
        {authors?.map((author, index) => (
          <NewsGridAuthorsName
            author={author.name}
            index={index}
            length={authors.length}
            key={author.name}
          />
        ))}
      </p>
    </div>
  </div>
);

export default NewsGridAuthors;
