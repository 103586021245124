import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import { HealthierPlanetScrollerProps } from './models';
import RightSideRenderer from './RightSideRenderer';

import './HealthierPlanetScroller.scss';

const HealthierPlanetScroller: FC<HealthierPlanetScrollerProps> = ({ module }) => {
  const CategoryTag = () =>
    module.categoryTitle ? (
      <span className="healthierPlanet__section-tag caption">{module.categoryTitle}</span>
    ) : null;

  const SectionTag = () =>
    module.sectionTag ? (
      <span className="healthierPlanet__category-tag caption">{module.sectionTag}</span>
    ) : null;

  return (
    <section
      className={classNames(`healthierPlanet ${module.backgroundColor}`, {
        'rte-light-grey': module.backgroundColor[0] === '',
        'rte-black': module.backgroundColor[0] === 'healthierPlanet--black',
      })}
    >
      <Container>
        <Row>
          <Col xl="5" md="6" sm="12" className="healthierPlanet__left-content col--no-gutters">
            <div className="healthierPlanet__left">
              <CategoryTag />
              <h2 className="healthierPlanet__title h2">{module.leftSideTitle}</h2>
              <div
                className="healthierPlanet__rte rte"
                dangerouslySetInnerHTML={{ __html: module.leftSideMainText }}
              />
              <LinkItem
                link={module.ctaLink}
                linkClass="healthierPlanet__btn btn btn--pink"
                titleClass="healthierPlanet__btn-text"
                hasIcon
                isLink
                showTitle
              />
            </div>
          </Col>
          <Col xl="7" md="6" className="col--no-gutters">
            <div className="healthierPlanet__right">
              <SectionTag />
              <RightSideRenderer items={module.rightSideItems} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HealthierPlanetScroller;
