import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import ContactUsBox from './ContactUsBox';
import { ContactUsInformationProps } from './models';

import './ContactUsInformation.scss';

const ContactUsInformation: FC<ContactUsInformationProps> = ({ module }) => (
  <section className="contact-info section">
    <Container>
      <Row>
        <Col className="contact-info__left col--no-gutters" sm="12" xl="5">
          <h2 className="contact-info__title h4">{module.title}</h2>
          {module.text ? <p className="contact-info__desc">{module.text}</p> : null}
          <LinkItem
            link={module.link}
            linkClass="contact-info__cta link link--pink"
            titleClass="test title class"
            hasIcon
            isLink
            showTitle
          />
        </Col>
        <Col className="contact-info__right col--no-gutters" sm="12" xl="6">
          <Row>
            {module.boxes?.map((box) => (
              <Col sm="12" md="6" key={box.title}>
                <ContactUsBox
                  key={box.title}
                  title={box.title}
                  phoneNumber={box.phoneNumber}
                  emailUsButtonText={box.emailUsButtonText}
                  email={box.email}
                  ctaLink={box.ctaLink}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

export default ContactUsInformation;
