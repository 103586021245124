import React, { FC, useContext } from 'react';

import { Col, Container, Row } from 'layout';
import Accordion from 'common/AccordionContainer/Accordion/Accordion';
import AccordionContainer from 'common/AccordionContainer/AccordionContainer';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import AccordionLinks from './AccordionLinks';
import AccordionMedia from './AccordionMedia';
import AccordionStats from './AccordionStats';
import { StrategyAccordionProps } from './models';

import './StrategyAccordion.scss';

const StrategyAccordion: FC<StrategyAccordionProps> = ({ module }) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  return (
    <section className="strategy-accordion section rte-light-grey">
      <Container>
        <Row>
          <Col className="col--no-gutters">
            <AccordionContainer showIndex firstChildOpened>
              {module.items?.map((item, index) => (
                <Accordion
                  key={`${item.title}-${index}`}
                  title={item.title}
                  titleFont="h4"
                  btnTextExpand={module.expandButtonText}
                  btnTextCollapse={module.collapseButtonText}
                >
                  <div className="strategy-accordion__content">
                    <div
                      className="accordion__rte rte"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                    {isMobile || isSmallMobile ? (
                      <AccordionMedia image={item.image} video={item.videoItem} />
                    ) : null}
                    <AccordionStats
                      stats={item.stats}
                      isMediaNotPresent={
                        !item.image && (!item.videoItem || item.videoItem.length <= 0)
                      }
                    />
                    <AccordionLinks links={item.links} />
                  </div>
                  {!isMobile && !isSmallMobile ? (
                    <AccordionMedia image={item.image} video={item.videoItem} />
                  ) : null}
                </Accordion>
              ))}
            </AccordionContainer>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StrategyAccordion;
