import React, { FC } from 'react';
import objectHash from 'object-hash';

import Caption from 'common/Caption';
import Image from 'common/Image';
import VideoItem from 'components/VideoAndImageWithText/VideoItem';

import { AccordionMediaProps } from './models';

const AccordionMedia: FC<AccordionMediaProps> = ({ image, video }) => {
  if (!image && (!video || video.length <= 0)) return null;

  const ariaId: string = objectHash({ image, video });

  return (
    <div className="accordion__media">
      {video && video.length > 0 ? (
        <>
          <VideoItem
            video={video[0].video}
            image={video[0].image}
            playButtonText={video[0].playVideoButtonText}
            ariaId={ariaId}
          />
          {video[0].captionText ? <Caption text={video[0].captionText} ariaId={ariaId} /> : null}
        </>
      ) : (
        <div className="accordion__image">
          <Image imageData={image} alt={image.altText} />
        </div>
      )}
    </div>
  );
};

export default AccordionMedia;
