import React, { FC } from 'react';

import { NewsGridAuthorsNameProps } from './models';

const NewsGridAuthorsName: FC<NewsGridAuthorsNameProps> = ({ author, length, index }) => (
  <span className="article__author-name">
    {' '}
    {author}
    {index === length - 1 ? null : ', '}
  </span>
);

export default NewsGridAuthorsName;
