import React, { FC } from 'react';

import { NewsGridTagsProps } from './models';
import NewsGridTag from './NewsGridTag';

import './NewsGridTags.scss';

const NewsGridTags: FC<NewsGridTagsProps> = ({ article }) => (
  <div className="article__tags">
    {article?.map((tag) => (
      <NewsGridTag tagName={tag.name} key={tag.name} />
    ))}
  </div>
);

export default NewsGridTags;
