import React, { FC } from 'react';

import ArticleMainImage from 'common/images/ArticleMainImage';

import { ImageItemProps } from './models';

const ImageItem: FC<ImageItemProps> = ({ image, ariaId }) => {
  if (!image) return null;

  return (
    <div className="image-and-video-slider__image">
      <ArticleMainImage
        imageData={image}
        alt={image.altText}
        className="image-and-video-slider__image-holder"
        aria-describedby={ariaId}
      />
    </div>
  );
};

export default ImageItem;
