import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';

import { SimpleCtaBlocksProps } from './models';
import SimpleCtaBlock from './SimpleCtaBlock';
import Title from './Title';

import './SimpleCtaBlocks.scss';

const SimpleCtaBlocks: FC<SimpleCtaBlocksProps> = ({ module }) => {
  const [widthOfBlocks, setWidthOfBlocks] = useState<string>('');

  useEffect(() => {
    switch (module.blocks?.length) {
      case 1:
        return setWidthOfBlocks('10');
      case 2:
        return setWidthOfBlocks('5');
      case 4:
        return setWidthOfBlocks('3');
      case 6:
        return setWidthOfBlocks('4');
      default:
        return setWidthOfBlocks('3');
    }
  }, []);

  return (
    <section
      className={classNames('section simple-cta-blocks', {
        'simple-cta-blocks--center': module.blocks?.length === 2 || module.blocks?.length === 3,
      })}
    >
      <Container>
        <Title title={module.title} />
        <Row element="ul" className="simple-cta-blocks__list">
          {module.blocks?.map((block) => (
            <Col
              element="li"
              key={block.text}
              xl={widthOfBlocks}
              md={module.blocks?.length === 2 ? '6' : '12'}
              sm="12"
              className="col--no-gutters simple-cta-blocks__item"
            >
              <SimpleCtaBlock text={block.text} ctaLink={block.ctaLink} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default SimpleCtaBlocks;
