import React, { FC } from 'react';
import classNames from 'classnames';

import { AccordionStatsProps } from './models';

const AccordionStats: FC<AccordionStatsProps> = ({ stats, isMediaNotPresent }) => {
  if (!stats || stats.length <= 0) return null;

  return (
    <div
      className={classNames('strategy-accordion__stat-container', {
        'no-media': isMediaNotPresent,
      })}
    >
      {stats.map((stat) => (
        <div className="strategy-accordion__stat" key={stat.boldStatistics + stat.description}>
          <img
            className="strategy-accordion__icon"
            src={stat.icon.url.trim() === '' ? stat.icon.fallbackUrl : stat.icon.url}
            alt={stat.iconAltText || ''}
          />
          <div>
            <h3 className="strategy-accordion__stat-numb h2">{stat.boldStatistics}</h3>
            <p className="strategy-accordion__stat-info caption">{stat.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionStats;
