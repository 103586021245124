import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import NewsGrid from 'common/NewsGrid/NewsGrid';
import { commonColorHandler } from 'utils/colorsHandler';

import { RelatedNewsProps } from './models';

import './RelatedNews.scss';

const RelatedNews: FC<RelatedNewsProps> = ({ module }) => {
  const {
    commonFields: { articleReadingTimeText, articleWrittenByText },
  } = useStaticQuery(graphql`
    {
      commonFields {
        articleReadingTimeText
        articleWrittenByText
        pulledArticlesNoResultsText
      }
    }
  `);

  const { backgroundColor, sectionTitle, summaryText, readMoreButton, articles } = module;

  return (
    <section
      className={classNames(`related-news ${commonColorHandler(backgroundColor[0])}`, {
        section: commonColorHandler(backgroundColor[0]) === 'related-news--white',
        'section-bg':
          commonColorHandler(backgroundColor[0]) === '' ||
          commonColorHandler(backgroundColor[0]) === 'related-news--light-pink',
      })}
    >
      <Container>
        <Row>
          <Col xl="6" md="6" sm="12" className="col--no-gutters related-news__col-content">
            <div className="related-news__content">
              <h2 className="related-news__title h2">{sectionTitle}</h2>
              <p className="related-news__text">{summaryText}</p>
              <LinkItem
                link={readMoreButton}
                linkClass={classNames('related-news__link link', {
                  'link--pink': commonColorHandler(backgroundColor[0]) === 'related-news--white',
                  'link--black-white':
                    commonColorHandler(backgroundColor[0]) === '' ||
                    commonColorHandler(backgroundColor[0]) === 'related-news--light-pink',
                })}
                titleClass="related-news__link-title"
                hasIcon
                isLink
                showTitle
              />
            </div>
          </Col>

          <NewsGrid
            sortedArticles={articles}
            numberOfCol={1}
            numberOfColTablet={1}
            lang={module.lang}
            readingTimeText={articleReadingTimeText}
            writtenByText={articleWrittenByText}
            hasTitle={!!module.sectionTitle}
          />
        </Row>
      </Container>
    </section>
  );
};
export default RelatedNews;
