import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { cutUrl } from 'utils/functions';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { StatisticsWithImagesItemProps } from './models';

import './StatisticsWithImagesItem.scss';

const StatisticsWithImagesItem: FC<StatisticsWithImagesItemProps> = ({ item }) => {
  const { isMobile, isTablet, isDesktop, isLargeDesktop } = useContext(ScreenRecognitionContext);
  const url = cutUrl(item.icon.url);

  return (
    <div className="statistics-with-images__blocks">
      <div className="statistics-with-images__blocks-icon">
        <img src={url} className="statistics-with-images__icon-blocks" alt={item.iconAltText} />
      </div>
      <div
        className={classNames('statistics-with-images__content', {
          'statistics-with-images__content--small': item.icon,
        })}
      >
        <h3
          className={classNames('statistics-with-images__blocks-title', {
            h1: isMobile,
            h2: isTablet || isDesktop || isLargeDesktop,
          })}
        >
          {item.boldStatistics}
        </h3>
        <div
          className="rte statistics-with-images__blocks-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
    </div>
  );
};

export default StatisticsWithImagesItem;
