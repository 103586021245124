import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Arrow from '../icons/arrow-right.svg';
import { ScrollDragProps } from './models';

import './ScrollDrag.scss';

const ScrollDrag: FC<ScrollDragProps> = ({
  colorOfSection,
  onSlideButton,
  btnOffset,
  onSlideButtonClicked,
  scrollDragName,
}) => {
  const [dragState, setDragState] = useState({ isScrolling: false, clientX: 0, scrollX: 0 });

  const scrollRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const forCalculate = useRef<HTMLDivElement>(null);

  let maxDrag: number;

  if (containerRef.current && scrollRef.current) {
    maxDrag = containerRef.current?.offsetWidth - scrollRef.current?.offsetWidth;
  }

  useEffect(() => {
    if (btnOffset !== undefined && forCalculate.current) {
      setDragState((prev) => ({
        ...prev,
        scrollX: forCalculate.current ? btnOffset * forCalculate.current.clientWidth : 0,
      }));
    }
  }, [btnOffset]);

  const onClickDown = (e, touch: boolean) => {
    e.persist();

    const isTouch = touch ? e.touches[0] : e;

    setDragState((prev) => ({
      ...prev,
      isScrolling: true,
      clientX: isTouch.clientX,
    }));

    onSlideButtonClicked(true);
  };

  const onClickUp = () => {
    setDragState((prev) => ({
      ...prev,
      isScrolling: false,
    }));

    onSlideButtonClicked(false);
  };

  const onMouseMove = (e, touch: boolean) => {
    e.persist();

    const { clientX, scrollX } = dragState;

    const isTouch = touch ? e.touches[0] : e;

    if (dragState.isScrolling && dragState.scrollX >= 0 && dragState.scrollX <= maxDrag) {
      setDragState((prev) => ({
        ...prev,
        scrollX:
          scrollX + isTouch.clientX - clientX > maxDrag
            ? maxDrag
            : scrollX + isTouch.clientX - clientX < 0
            ? 0
            : scrollX + isTouch.clientX - clientX,
        clientX: isTouch.clientX,
      }));
    }

    if (forCalculate.current) {
      onSlideButton(dragState.scrollX / forCalculate.current?.offsetWidth);
    }
  };

  const classesForDiv = classNames('scrollDrag', {
    'scrollDrag--grey': colorOfSection === 'grey',
    'scrollDrag--black': colorOfSection === 'black',
  });

  const widthForCalculate =
    containerRef.current && scrollRef.current
      ? containerRef.current?.offsetWidth - scrollRef.current?.offsetWidth
      : '';

  const onKeyDownHandle = (e) => {
    if (e.key === 'ArrowRight') {
      setDragState((prev) => ({
        clientX: prev.scrollX + 10 > maxDrag ? maxDrag : prev.scrollX + 10,
        scrollX: prev.scrollX + 10 > maxDrag ? maxDrag : prev.scrollX + 10,
        isScrolling: true,
      }));
    }

    if (e.key === 'ArrowLeft') {
      setDragState((prev) => ({
        clientX: prev.scrollX - 10 < 0 ? 0 : prev.scrollX - 10,
        scrollX: prev.scrollX - 10 < 0 ? 0 : prev.scrollX - 10,
        isScrolling: true,
      }));
    }

    if (forCalculate.current) {
      onSlideButton(dragState.scrollX / forCalculate.current?.offsetWidth);
    }

    onSlideButtonClicked(true);
  };

  const onKeyUpHandle = (e) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      setDragState((prev) => ({
        ...prev,
        isScrolling: false,
      }));

      onSlideButtonClicked(false);
    }
  };

  const widthOfGradient = dragState?.scrollX + 1;

  return (
    <div className={classesForDiv} ref={containerRef}>
      <span
        className="scrollDrag__scroll-gradient"
        style={{ width: widthOfGradient }}
        aria-hidden="true"
      />
      <div className="scrollDrag__calc" style={{ width: widthForCalculate }} ref={forCalculate} />
      <button
        className="scrollDrag__btn"
        style={{ transform: `translateX(${dragState.scrollX}px)` }}
        onMouseDown={(e) => onClickDown(e, false)}
        onMouseUp={onClickUp}
        onMouseMove={(e) => onMouseMove(e, false)}
        onTouchStart={(e) => onClickDown(e, true)}
        onTouchMove={(e) => onMouseMove(e, true)}
        onTouchEnd={onClickUp}
        onKeyDown={onKeyDownHandle}
        onKeyUp={onKeyUpHandle}
        ref={scrollRef}
        type="button"
      >
        <Arrow className="scrollDrag__scroll scrollDrag__scroll--left" aria-hidden="true" />
        <span className="scrollDrag__scroll-text">{scrollDragName}</span>
        <Arrow className="scrollDrag__scroll scrollDrag__scroll--right" aria-hidden="true" />
      </button>
    </div>
  );
};

export default ScrollDrag;
