import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { Col, Container, Row } from 'layout';
import ScrollDrag from 'common/ScrollDrag';
import { isBrowser } from 'utils/browser';

import { TableProps } from './models';

import './Table.scss';

const Table: FC<TableProps> = ({ module }) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const leftGradientRef = useRef<HTMLDivElement>(null);
  const rightGradientRef = useRef<HTMLDivElement>(null);
  const [screen, setScreen] = useState<number | boolean>();
  const [scrolled, setScrolled] = useState<number>(0);
  const [isScrollStopped, setIsScrollStopped] = useState<boolean>(false);

  const slideButtonHandle = (slide: number) => {
    if (wrapRef.current) {
      const scrollRefWidth = (wrapRef?.current.scrollWidth - wrapRef.current.clientWidth) * slide;
      wrapRef.current.scrollLeft = scrollRefWidth;
    }
  };

  const scrollHandle = (e) => {
    if (wrapRef.current && !isScrollStopped) {
      setScrolled(
        e.target.scrollLeft / (wrapRef.current.scrollWidth - wrapRef.current.clientWidth)
      );
    }
    if (leftGradientRef.current && wrapRef.current && screen) {
      if (
        wrapRef.current.scrollWidth > wrapRef.current.clientWidth &&
        (e.target.scrollLeft / (wrapRef.current.scrollWidth - wrapRef.current.clientWidth)) * 100 >
          2
      ) {
        leftGradientRef.current.style.display = 'block';
      } else {
        leftGradientRef.current.style.display = 'none';
      }
    }
    if (rightGradientRef.current && wrapRef.current && screen) {
      if (
        wrapRef.current.scrollWidth > wrapRef.current.clientWidth &&
        (e.target.scrollLeft / (wrapRef.current.scrollWidth - wrapRef.current.clientWidth)) * 100 <
          98
      ) {
        rightGradientRef.current.style.display = 'block';
      } else {
        rightGradientRef.current.style.display = 'none';
      }
    }
  };

  const stopTouchScrollHandle = (isScrolling: boolean) => {
    setIsScrollStopped(isScrolling);
  };

  const handleResize = debounce(() => {
    setScreen(window.innerWidth);
  }, 500);

  useEffect(() => {
    if (isBrowser()) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollDrag = useMemo(
    () => (
      <>
        {wrapRef.current && wrapRef.current.scrollWidth > wrapRef.current.clientWidth ? (
          <ScrollDrag
            onSlideButton={slideButtonHandle}
            btnOffset={scrolled}
            onSlideButtonClicked={stopTouchScrollHandle}
            scrollDragName={module?.dragButtonText}
          />
        ) : null}
      </>
    ),
    [screen, scrolled]
  );

  return (
    <section className="table section">
      <Container>
        <Row>
          <Col className="col--no-gutters">
            <div className="table__container">
              {module.title ? <h2 className="table__section-title h6">{module.title}</h2> : null}
              <div className="table__outer">
                <div
                  className="table__inner"
                  ref={wrapRef}
                  onScroll={(e) => {
                    scrollHandle(e);
                  }}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                >
                  <div className="table__gradient table__gradient--left" ref={leftGradientRef} />
                  <div className="table__gradient table__gradient--right" ref={rightGradientRef} />
                  {module.content ? (
                    <div dangerouslySetInnerHTML={{ __html: module.content }} />
                  ) : null}
                </div>
              </div>
              {scrollDrag}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Table;
