import React, { FC, useMemo, useState } from 'react';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Caption from 'common/Caption';
import CustomCarousel from 'components/CustomCarousel';

import ImageItem from './ImageItem';
import { ImageAndVideoSliderProps } from './models';
import VideoItem from './VideoItem';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageAndVideoSlider.scss';

const ImageAndVideoSlider: FC<ImageAndVideoSliderProps> = ({ module }) => {
  const [index, setIndex] = useState(0);

  const ariaIDs = useMemo(() => module.items.map((item) => objectHash(item)), [module.items]);

  const items = useMemo(
    () =>
      module.items.length > 1 ? (
        <CustomCarousel
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          onChange={setIndex}
        >
          {module.items.map((item, i) => (
            <div className="image-and-video-slider__slide" key={ariaIDs[i]}>
              {!item.video ? (
                <ImageItem image={item.image} ariaId={ariaIDs[i]} />
              ) : (
                <VideoItem
                  video={item.video}
                  image={item.image}
                  playButtonText={item.playVideoButtonText}
                  ariaId={ariaIDs[i]}
                />
              )}
            </div>
          ))}
        </CustomCarousel>
      ) : !module.items[0].video ? (
        <ImageItem image={module.items[0].image} ariaId={ariaIDs[0]} />
      ) : (
        <VideoItem
          video={module.items[0].video}
          image={module.items[0].image}
          playButtonText={module.items[0].playVideoButtonText}
          ariaId={ariaIDs[0]}
        />
      ),
    [module.items, ariaIDs]
  );

  return (
    <section className="image-and-video-slider section">
      <Container>
        <Row className="image-and-video-slider__container row--center">
          <Col xl="10" className="col--no-gutters">
            {items}
          </Col>
          {module.items[index].caption ? (
            <Col xl="10" className="col--no-gutters">
              <Caption text={module.items[index].caption} ariaId={ariaIDs[index]} />
            </Col>
          ) : null}
        </Row>
      </Container>
    </section>
  );
};

export default ImageAndVideoSlider;
