import React, { FC } from 'react';

import ArticlePreviewNewsAndStoriesImage from 'common/images/ArticlePreviewNewsAndStoriesImage';

import { NewsGridImageProps } from './models';

import './NewsGridImage.scss';

const NewsGridImage: FC<NewsGridImageProps> = ({ article }) => {
  if (!article) return null;

  return (
    <div className="article__main-img" aria-hidden="true">
      <ArticlePreviewNewsAndStoriesImage
        className="article__main-pic"
        imageData={article}
        alt={article.altText}
      />
    </div>
  );
};

export default NewsGridImage;
