import React, { FC, useContext, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from 'hooks/useOnScreen';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { TitleAndTextProps } from './models';

import './TitleAndText.scss';

const TitleAndText: FC<TitleAndTextProps> = ({ title, text }) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  const visibleRef = useRef(null);
  const isVisible = useOnScreen(visibleRef, 0);

  const classesForDiv = classNames('healthierPlanet__title-text healthierPlanet__right-content', {
    'healthierPlanet__title-text--visible': isVisible && !isMobile && !isSmallMobile,
  });

  const classesForDesc = classNames('rte healthierPlanet__title-text-desc', {
    'tag tag--bold': isMobile || isSmallMobile,
    h6: !isMobile && !isSmallMobile,
  });

  return (
    <div ref={visibleRef} className={classesForDiv}>
      <h3 className="healthierPlanet__title-text-title h1">{title}</h3>
      {text ? <div className={classesForDesc} dangerouslySetInnerHTML={{ __html: text }} /> : null}
    </div>
  );
};

export default TitleAndText;
