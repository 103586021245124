import React, { FC } from 'react';

import StatisticsWithImagesItem from '../StatisticsWithImagesItem';
import { StatisticsWithImagesItemsProps } from './models';

const StatisticsWithImagesItems: FC<StatisticsWithImagesItemsProps> = ({ block }) => (
  <>
    {block.items?.map((item) => (
      <StatisticsWithImagesItem item={item} key={item.boldStatistics} />
    ))}
  </>
);

export default StatisticsWithImagesItems;
