import React, { FC } from 'react';

import StatisticsWithImagesBlock from '../StatisticsWithImagesBlock';
import { StatisticsWithImagesBlocksProps } from './models';

const StatisticsWithImagesBlocks: FC<StatisticsWithImagesBlocksProps> = ({ module }) => (
  <>
    {module.blocks?.map((block) => (
      <StatisticsWithImagesBlock block={block} key={block.image.altText} />
    ))}
  </>
);

export default StatisticsWithImagesBlocks;
